import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Columns } from "react-bulma-components"

import Layout from "../../components/Layout"
import FaqItem from "../../components/FaqItem"


const News = () => {
    const data = useStaticQuery(
        graphql`
            query FaqQuery {
                allMarkdownRemark(
                    filter: {
                        fields: {
                            sourceName: {eq: "faq"}
                        }
                    }
                    sort: {
                        fields: [frontmatter___date]
                        order: DESC
                    }
                ) 
                {
                    edges {
                        node {
                            id
                            frontmatter {
                                title
                                date(formatString: "DD MMMM YYYY"
                                     locale: "ru-RU")
                            }
                            html
                            fields {
                                sourceName
                            }
                        }
                    }
                }
            }
        `
    )
    const news = data.allMarkdownRemark.edges
    const description = 
        "Вопросы/ответы. На этой странице Вы найдете ответы на часто задаваемые вопросы, поступающие от посетителей нашего сайта"
    return (
        <Layout 
            title="Вопросы и ответы" 
            subtitle="На этой странице Вы найдете наиболее часто задаваемые вопросы."
            description={description}
            slug="/main/faq/"
        >
            {news.map(({ node }) => (
                <Columns key={node.id}>
                    <Columns.Column>
                        <FaqItem 
                            title={node.frontmatter.title}
                            date={node.frontmatter.date}
                        >
                            <div dangerouslySetInnerHTML={{ __html: node.html }} />
                        </FaqItem>
                    </Columns.Column>
                </Columns>
            ))}
        </Layout>
    )
}

export default News